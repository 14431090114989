import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getInsuranceClientSecret, getVerticalInsuranceQuote, getExistingPolicy } from 'context/actions/pet-owner-dashboard';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { Formik, Form } from 'formik';
import { preexistingSchema, breedSchema} from "./schema"
import { completePurchase } from 'context/actions/pet-owner-dashboard';
import { FormFeedback } from 'reactstrap';
import LoaderUI from 'shared/loader';
import Select from 'react-select';
import moment from 'moment';
import Swal from 'sweetalert2';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "shared/Payment"
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { getPolicyDocument } from 'context/actions/pet-owner-dashboard'; 


const stripePromise = loadStripe("pk_live_51LS5CILb4tFtlrQypTAUJxu1Z06nIa84okTaoBE2Mbq8wGYtIsn4RdmvVw7cYTA0hagE4gvFkBExlWT5dcjsv84D00fPRGWbQf", {stripeAccount: 'acct_1PdyqMCDBY9vBAyB'});




const InsuranceComponent = () => {
  const [loading, setLoading] = useState(true)
  const [quoteInfo, setQuoteInfo] = useState(false)
  const [policyInfo, setPolicyInfo] = useState(false)
  const [breeds, setBreeds] = useState([]);
   
  const [matchBreed, setMatchBreed] = useState(true)
  const [preexisting, setPreexsting] = useState(false)
  const [step, setStep] = useState(1)
  const [inelligible, setInelligible] = useState(false)



  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const userDetail = useSelector((state) => state.user.user_details);
  const [clientSecret, setClientSecret] = useState(false)


  const dispatch = useDispatch();


  // const getClientSecret = async () => {
  //   let res = await dispatch(getInsuranceClientSecret(userDetail.email))
  //   console.log(res)
  //  if(res.status) {
  //   setClientSecret(res.response_data.secret)

  //  }

  // }

  const handleShowPolicyDocument = () => {
    setLoading(true)

    dispatch(getPolicyDocument(petDetails?._id, (response) => {
      console.log('existing policy response')
      if(response?.status){

          dispatch({
            type: "SET_FILE_VIEWER_DATA",
            data: {
              title: 'Vertical Insurance Policy',
              fileURL: response?.response_data?.url,
            },
          });
        
      } else {
        //getQuote();        setLoading(false)

      }
      setLoading(false)
    }))

  }

  const getPolicyInfo = () => {
    console.log('getting policy info')
    dispatch(getExistingPolicy(petDetails?._id, (response) => {
      console.log('existing policy response')
      if(response?.status){
        console.log('setting policy info')
        setPolicyInfo(response?.response_data)
      } else {
        console.log('getting quote info')
        //getQuote();        setLoading(false)

      }
      setLoading(false)
    }))
  }

  const getQuote = () => {
    setLoading(true);

   dispatch(getVerticalInsuranceQuote(petDetails?._id, (response) => {
    if(response && response?.status){
      setQuoteInfo(response?.response_data)
      setClientSecret(response?.response_data?.client_secret)

      setLoading(false);
      setMatchBreed(false)
    } else if(response && !response?.status && response?.message == "Could not match a breed") {
      setMatchBreed(true);
      console.log(petDetails)
      setBreeds(response?.response_data?.[petDetails?.speciesName?.toUpperCase()])
      console.log(response?.response_data?.[petDetails?.speciesName?.toUpperCase()])
      setLoading(false);

    } else {

      setInelligible(true)
      setLoading(false)

    }
    setLoading(false);
   }))
  }

  useEffect(() =>{
    if(clientSecret){
      setMatchBreed(false)
    }

  }, [clientSecret])

  const selectBreed = (breed) => {
    setLoading(true);
    console.log(breed)
    dispatch(getVerticalInsuranceQuote(petDetails?._id, (response) => {
      if(response && response?.status){
        setQuoteInfo(response?.response_data)
        setLoading(false);
        setClientSecret(response?.response_data?.client_secret)

  
      } else if(response && !response?.status && response?.message == "Could not match a breed") {
        setMatchBreed(true);
        console.log(petDetails)
        setBreeds(response?.response_data?.[petDetails?.speciesName?.toUpperCase()])
        console.log(response?.response_data?.[petDetails?.speciesName?.toUpperCase()])
        setLoading(false);
  
      }
      setLoading(false);
     }, breed))

  }

  const resetData = () => {
    setQuoteInfo(false)
    setPolicyInfo(false)
    setStep(1)
    setInelligible(false)


   }
 

  useEffect(() => {
    setLoading(true);
    resetData();
    getPolicyInfo();


  }, [petDetails])


  
  const purchase =  (values) => {
 
    try {

      setLoading(true)

      //pass token to backend to purchase insurance
      dispatch(completePurchase( { quote: quoteInfo?.quote_id, customer: quoteInfo?.policy_holder ,  payment: values, patient_id: petDetails?._id}, (res) => {
        if(res?.status) {

              Swal.fire({
                title: "<strong>Congratulations on your new policy!</strong>",
                icon: "success",
                html: `Your policy details will be emailed to you shortly`,
          
                focusConfirm: false,
                confirmButtonText:"Ok",
                confirmButtonAriaLabel: "Ok",
               
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  getPolicyInfo();

                } 
            })
            } else { 
              Swal.fire({
                title: "<strong>Policy Purchase Error</strong>",
                icon: "warning",
                html: `Unfortunately your policy could not be purchased at this time, please try again later or with a different payment method`,
          
                focusConfirm: false,
                confirmButtonText:"Ok",
                confirmButtonAriaLabel: "Ok",
               
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                resetData()
                setLoading(false)
            })
            }
      })); // this function could be a fetch call, and could throw an error
    } catch (e) {
      //the web component will display a card validation error if anything is missing, but will also throw a ValidationError up
    
    }
    
  }



  return (


    <>
    {loading ? <LoaderUI  /> :

 
    policyInfo ?
    
    <>

    <div style={{marginLeft: '59px', marginTop: '40px', gap: '10px', maxWidth: '800px', background: 'rgb(236, 239, 254)'}}  className='d-flex flex-col medical-breif-box'>

        <div className='d-flex flex-row align-items-center justify-content-between'>
          <div className="d-flex flex-row align-items-center">
          <img style={{width: 89, height: 89}} src={require('../../assets/img/vertical/Vi-logo.png')} />
          <h1 style={{fontSize: '20px', marginLeft: '20px'}}>Vertical Pet Insurance</h1> 
          </div>


          <div className="fa-center gap-3 cursor-pointer">

            { policyInfo?.policy_documents?.length > 0  && 
                    <div
                      onClick={handleShowPolicyDocument}
                    >
                      <MdOutlineRemoveRedEye size={20} color="#919191" />
                    </div>
            }
          </div>
      </div>

      <div className='d-flex flex-row gap-[5px]'>


      <div style={{border: '1px solid rgba(40, 46, 101, 0.1)', borderRadius: '8px', padding: '8px'}} className='d-flex  justify-content-between flex-row gap-[10px]'>


<div className='d-flex flex-col' >
  <h3>Status:{' '}</h3> 
  </div>
<div className='d-flex flex-col'>
  <h3>{policyInfo?.policy_status}
  </h3></div>
</div>

      <div style={{border: '1px solid rgba(40, 46, 101, 0.1)', borderRadius: '8px', padding: '8px'}} className='d-flex  justify-content-between flex-row gap-[10px]'>
      



<div className='d-flex flex-col' >
  <h3>Effective Date:{' '}</h3> 
  </div>
<div className='d-flex flex-col'>
  <h3>{moment(policyInfo?.effective_date, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
  </h3></div>
</div>

<div style={{border: '1px solid rgba(40, 46, 101, 0.1)', borderRadius: '8px', padding: '8px'}} className='d-flex  justify-content-between gap-[10px] flex-row'>
<div className='d-flex flex-col'>
  <h3>
  Expiration Date:{' '}
  </h3>
  </div>
<div className='d-flex flex-col' >
  <h3>
  {moment(policyInfo?.expiration_date, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
  </h3></div>



</div>


      </div>
          <p><small><i>Policyholders will be notified by Vertical Insure via email within 30 days from their renewal date with instructions to renew their coverage or cancel if needed. Contact support@verticalinsure.com with any questions.</i></small></p>
    </div>



    
    
    </>
    
    
    :
    inelligible ? 
    <>


    <div style={{marginLeft: '59px', marginTop: '40px', gap: '10px', maxWidth: '800px', background: 'rgb(236, 239, 254)'}}  className='d-flex flex-col medical-breif-box'>   

      <div className='d-flex flex-row align-items-center'>

      <img style={{width: 89, height: 89}} src={require('../../assets/img/vertical/Vi-logo.png')} />
      <h1 style={{fontSize: '20px', marginLeft: '20px'}}>Vertical Pet Insurance</h1> 
      </div>
     
      <div className='d-flex flex-col'>
        <p>Unfortunately your pet is not currently elligible for a Vertical Insurance Policy given its medical history or species</p>
         
    </div>

  </div>
    
    
    </> :
    
   step === 1 ?
    (
    <>


    <div style={{marginLeft: '59px', marginTop: '40px', gap: '10px', maxWidth: '800px', background: 'rgb(236, 239, 254)'}}  className='d-flex flex-col medical-breif-box' >   

      <div className='d-flex flex-row align-items-center'>

      <img style={{width: 89, height: 89}} src={require('../../assets/img/vertical/Vi-logo.png')} />
      <h1 style={{fontSize: '20px', marginLeft: '20px'}}>Vertical Pet Insurance</h1> 
      </div>
      {/* <div>
      
        <select>
          <option value="">Please select a breed</option>
          {breeds?.map((breed) => 
            <option value={breed?.id}>{breed?.name}</option>
          )}


        </select>
      </div> */}
      <div className='d-flex flex-col'>
        <p>Has your pet ever shown symptoms, received treatment for, or been diagnosed with the following conditions?</p>
        <p style={{paddingTop: '10px'}}>  Addison’s Disease, Cushing’s Disease, Diabetes, Cancer, Feline Leukemia or Feline Immunodeficiency Virus</p>
          <div style={{paddingTop: '10px'}}>

          <Formik
							initialValues={{}}
							enableReinitialize
							validationSchema={preexistingSchema} 
							onSubmit={(values) => {
                if(values?.preexisting === 'no'){
                  setStep(2)
                  getQuote()
                } else {
                  setInelligible(true)
                }
              }}
              >
                 {(formik) => ( 
                  <Form>
            

              <FormGroup tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="preexisting" value="yes" onChange={formik.handleChange} checked={formik.values.preexisting === 'yes'}  />{' '}
                   Yes
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="preexisting"  value="no"  onChange={formik.handleChange} checked={formik.values.preexisting === 'no'} />{' '}
                    No
                  </Label>
                </FormGroup>
                <div className='w-100 d-flex justify-content-end'>
                <Button className="ml-auto"  type="submit">Submit</Button>
                </div>

              </FormGroup>
            </Form>)}
            </Formik>

           
          </div>
    </div>

  </div>
    
    
    </> ) :

    matchBreed ? 
    <>
     <div style={{marginLeft: '59px', marginTop: '40px', gap: '10px', maxWidth: '800px', background: 'rgb(236, 239, 254)'}} className='d-flex flex-col medical-breif-box' >   

        <div className='d-flex flex-row align-items-center'>

        <img style={{width: 89, height: 89}} src={require('../../assets/img/vertical/Vi-logo.png')} />
        <h1 style={{fontSize: '20px', marginLeft: '20px'}}>Vertical Pet Insurance</h1> 
        </div>


        <Formik
							initialValues={{}}
							enableReinitialize
							validationSchema={breedSchema} 
							onSubmit={(values) => {
                selectBreed(values?.breed?.value)
              }}
              >
            {(formik) => ( 
            <Form>
   
        <div className='d-flex flex-col' style={{gap: '10px'}}>
          <p>We couldn't find a match for yor pet's breed, please select a breed for a customized quote </p>


          <Select 
          
        
          value={formik.values.breed} onChange={i => formik.setFieldValue('breed', i)} name="breed" options={breeds?.map((breed) =>{return {value: breed?.id, label: breed?.name}})} />

              <Button type="submit" style={{width: 'fit-content', marginLeft: 'auto'}}>Proceed</Button>
            

        </div>
        </Form>
        )}
        </Formik>
        </div>
    </> :

     <Elements options={{clientSecret: clientSecret, appearance: {theme: 'stripe'}}} stripe={stripePromise} >


    
{/* 
             <Formik
							initialValues={paymentInit}
							enableReinitialize
							validationSchema={paymentSchema} 
							onSubmit={(values) => {
                purchase(values)
              }}
              >
                {(formik) => ( 
                  <Form>


      <> */}



      <div style={{ display:'flex', flexDirection: 'column', marginLeft: '59px', marginTop: '40px', background: '#ECEFFE', borderRadius: 20, padding: '60px', maxWidth: '884px', gap:'10px',  position: 'relative'}}>

      <div style={{width: 278, height: 60, background: '#304AF5', borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center',  position: 'absolute', top:-20}}>
      <div style={{ textAlign: 'center', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>PET INSURANCE</div>


        </div>

        <div className='d-flex flex-row justify-content-between'>

          <div style={{ color: 'black', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Injury and Illness Plan</div>
          <div style={{  textAlign: 'right', color: '#304AF5', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>${quoteInfo?.subscription_premium?.payment_amount / 100}/mo</div>
        </div>

        <div style={{  marginTop:'30px', color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Treat your pet like family with a comprehensive insurance plan designed to help with the costs of vet visits or emergency care when your dog or cat is sick or injured. </div>
        <div style={{  marginBottom: '13px',   color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Cover treatments for:</div>

        {/* <div style={{width: 884, height: 798, left: 0, top: 0, background: '#ECEFFE', borderRadius: 20}} /> */}
      
   
     
     
     
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px'}}>
          <div className='d-flex flex-col gap-[5px]'>
            <div style={{left: 384, top: 68, display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
            <img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />

              <div style={{ left: 44, top: 4,  color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Specialist Care</div>
          </div>
          <div style={{ left: 384, top: 34, display: 'flex', flexDirection: 'row'}}>
          <img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />

            <div style={{ left: 44, top: 6,color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Hospitalization & surgery</div>
          </div>
          <div style={{ left: 384, top: 0,display: 'flex', flexDirection: 'row' }}>
          <img style={{width: 38, height: 38, left: 0, top: 0 }} src={require('../../assets/img/vertical/check-icon.png')} />

            <div style={{ left: 43, top: 7, color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Emergency care</div>
          </div>
          </div>
          <div className='d-flex flex-col gap-[5px]'>

          <div style={{ left: 0, top: 68,display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />

            <div style={{ left: 39, top: 4,  color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Diagnostics & testing</div>
          </div>
          <div style={{ left: 0, top: 34,display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />

            <div style={{ left: 39, top: 6, color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Illnesses</div>
          </div>
          <div style={{ left: 0, top: 0,display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />

            <div style={{left: 38, top: 7,  color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Accidents & injuries</div>
          </div>
          </div>
  

</div> 
  

      <Payment clientSecret={clientSecret} callback={(intent) => purchase(intent?.id)} />

      {/* <div style={{width: 278, height: 60, left: 549, top: 578}}>
              <div style={{width: 278, height: 60, left: 0, top: 0, background: '#304AF5', borderRadius: 15}} />
              <button style={{width: 278, height: 44, left: 0, top: 6, textAlign: 'center', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}} type="submit">PURCHASE</button>
        </div>  */}

<div >
    {/* <div className='d-flex flex-row align-items-center'>
    <img style={{width: 89, height: 89}} src={require('../../assets/img/vertical/Vi-logo.png')} />

        <div className='d-flex flex-col' >
          <span style={{color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Provided by Vertical Insure, Inc.
          </span>
          <span style={{color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>support@verticalinsure.com</span>
        </div>
      </div> */}
        <div style={{ color: 'black', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Vertical Insure, Inc. wholly owns Next Wave Insurance Services LLC (License #8659525), the agency selling Odie Pet Insurance policies. Insurance coverage is administered by Norse Specialty Insurance Company, Inc. and underwritten by Trisura Insurance Company. Next Wave receives compensation based on the premiums of the insurance policies it sells. Each insurer bears sole financial responsibility for its products. By purchasing, you agree to the Terms and Conditions and Electronic Delivery Terms. You can view a sample policy here.</div>
      
      </div>

</div> 
{/* <div style={{width: '100%', height: '100%', marginLeft: '59px', marginTop: '40px', position: 'relative'}}>
  <div style={{width: 884, height: 798, left: 0, top: 0, , background: '#ECEFFE', borderRadius: 20}} />
  <div style={{width: 797, height: 191, left: 46, top: 566, }}>
  <div style={{width: 787, height: 92, left: 10, top: 99, , color: 'black', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Vertical Insure, Inc. wholly owns Next Wave Insurance Services LLC (License #8659525), the agency selling Odie Pet Insurance policies. Insurance coverage is administered by Norse Specialty Insurance Company, Inc. and underwritten by Trisura Insurance Company. Next Wave receives compensation based on the premiums of the insurance policies it sells. Each insurer bears sole financial responsibility for its products. By purchasing, you agree to the Terms and Conditions and Electronic Delivery Terms. You can view a sample policy here.</div>
  <div style={{width: 384, height: 44, left: 89, top: 23, }}>
    <span style={{color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Provided by Vertical Insure, Inc.
    </span>
    <span style={{color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>support@verticalinsure.com</span>
  </div>
  <img style={{width: 89, height: 89, left: 0, top: 0, }} src={require('../../assets/img/vertical/Vi-logo.png')} />
</div>
<div style={{width: 278, height: 60, left: 549, top: 578, }}>
  <div style={{width: 278, height: 60, left: 0, top: 0, , background: '#304AF5', borderRadius: 15}} />
  <button style={{width: 278, height: 44, left: 0, top: 6, , textAlign: 'center', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}} type="submit">PURCHASE</button>
</div> */}
{/* <div style={{width: 1035, height: 179, left: 60, top: 365, }}>
  <div style={{width: 516, height: 77, left: 519, top: 102, }}>
    <div style={{width: 244, height: 50, left: 0, top: 27, , background: 'white', borderRadius: 10}} />
    {formik.errors.cvv && formik.touched.cvv ? <div className='text-danger' style={{position:'absolute', top: 80, left: 20 , zIndex: 100}}>{formik.errors.cvv}</div> : null}

    <input name="cvv" value={formik.values.ccv} onChange={formik.handleChange} style={{width: 222, height: 25, left: 24, top: 40, , color: '#96A6F6', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word', zIndex: 10}} maxLength={4} placeholder='CVV' />
    <div style={{width: 504, height: 27, left: 12, top: 0, , color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>CVV</div>
  </div>
<div style={{width: 765, height: 152, left: 0, top: 27, }}>
  <input name="year" value={formik.values.year} onChange={formik.handleChange}  style={{width: 224, borderRadius: 10, paddingLeft:'10px', height: 50, left: 278, top: 102, , color: '#96A6F6', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word', zIndex: 10}} maxLength={4} placeholder='YYYY'/>
  <div style={{width: 244, height: 50, left: 260, top: 102, , background: 'white', borderRadius: 10}} />
  {formik.errors.year && formik.touched.year ? <div className='text-danger' style={{position:'absolute', top: 150, left: 287 , zIndex: 100}}>{formik.errors.year}</div> : null}
  {formik.errors.month && formik.touched.month ? <div className='text-danger' style={{position:'absolute', top: 150, left: 20 , zIndex: 100}}>{formik.errors.month}</div> : null}

  <input name="month" value={formik.values.month} onChange={formik.handleChange}  style={{width: 244, height: 50, borderRadius: 10, left: 2, top: 100, , color: '#96A6F6', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word', paddingLeft: '20px', zIndex: 10}} placeholder='MM' maxLength={2} />
  <div style={{width: 765, height: 50, left: 0, top: 0, , background: 'white', borderRadius: 10}} />
  <div style={{width: 504, height: 27, left: 12, top: 75, , color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Expiration</div>
</div>
<div style={{width: 777, height: 179, left: 0, top: 0, }}>
  {formik.errors.cc && formik.touched.cc ? <div className='text-danger' style={{position:'absolute', top: 80, left: 20 , zIndex: 100}}>{formik.errors.cc}</div> : null}

  <input name="cc" value={formik.values.cc
              .replace(/\s/g, "")
              .replace(/([0-9]{4})/g, "$1 ")
              .trim()} onChange={formik.handleChange}  style={{width: 730, height: 25, left: 20, top: 40, , color: '#96A6F6', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}} maxLength={19} placeholder="1234 1234 1234 1234"/>
  <div style={{width: 767, height: 27, left: 10, top: 0, , color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Card Number</div>
</div>
</div> */}

  {/* <Payment style={{width: 1035, height: 179, left: 60, top: 365, }} clientSecret={clientSecret} /> */}

  {/* <div style={{width: 767, height: 277, left: 60, top: 68, }}>
    <div style={{width: 754, height: 106, left: 10, top: 171, }}>
      <div style={{width: 370, height: 38, left: 384, top: 68, }}> 
      <div style={{width: 326, height: 27, left: 44, top: 4, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Specialist Care</div>
<img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />
</div>
<div style={{width: 370, height: 38, left: 384, top: 34, }}>
<div style={{width: 326, height: 27, left: 44, top: 6, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Hospitalization & surgery</div>
<img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />
</div>
<div style={{width: 369, height: 38, left: 384, top: 0, }}>
<div style={{width: 326, height: 27, left: 43, top: 7, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Emergency care</div>
<img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />
</div>
<div style={{width: 365, height: 38, left: 0, top: 68, }}>
<div style={{width: 326, height: 27, left: 39, top: 4, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Diagnostics & testing</div>
<img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />
</div>
<div style={{width: 365, height: 38, left: 0, top: 34, }}>
<div style={{width: 326, height: 27, left: 39, top: 6, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Illnesses</div>
<img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />
</div>
<div style={{width: 364, height: 38, left: 0, top: 0, }}>
<div style={{width: 326, height: 27, left: 38, top: 7, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Accidents & injuries</div>
<img style={{width: 38, height: 38, left: 0, top: 0, }} src={require('../../assets/img/vertical/check-icon.png')} />
</div>
</div>
<div style={{width: 767, height: 27, left: 0, top: 138, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Cover treatments for:</div>
<div style={{width: 765, height: 85, left: 2, top: 53, , color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Treat your pet like family with a comprehensive insurance plan designed to help with the costs of vet visits or emergency care when your dog or cat is sick or injured. </div>
<div style={{width: 354, height: 23, left: 396, top: 0, , textAlign: 'right', color: '#304AF5', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>${quoteInfo?.subscription_premium?.payment_amount / 100}/mo</div>
<div style={{width: 476, height: 23, left: 0, top: 0, , color: 'black', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>Injury and Illness Plan</div>
 






</div> 
<div style={{width: 278, height: 60, left: 0, top: -30, , background: '#304AF5', borderRadius: 15}} />
<div style={{width: 278, height: 44, left: 0, top: -13, , textAlign: 'center', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}}>PET INSURANCE</div>
</div> */}





{/* </>
</Form>)}
</Formik> */}
 </Elements> }

</>

      
      



      

  )

}

export default InsuranceComponent