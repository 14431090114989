import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  deleteOperatingRooms,
  getOperatingRoomsByClinicId,
} from "context/actions/operatingRoomAction";
import "./index.css";

const OperatingRoomList = ({ setSelectedOperatingRoom }) => {
  const { operatingRooms } = useSelector((state) => state.operatingRoomReducer);
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const handleDeleteOperetingList = async (id) => {
    Swal.fire({
      title:
        "<strong>Are you sure you want to delete this operating room?</strong>",
      icon: "warning",
      html: `Once deleted, your data cannot be recovered`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirm",
      confirmButtonAriaLabel: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteOperatingRooms(id))
          .then((res) => {
            if (res?.status) {
              toast.success(res?.msg);
            }
            return res;
          })
          .then((res) => {
            if (res?.status) {
              fetchOperatingRoomsData();
            }
          });
      }
    });
  };

  const fetchOperatingRoomsData = () => {
    dispatch(
      getOperatingRoomsByClinicId(clinic_settings.clinic_id, {}, (res) => {})
    );
  };

  useEffect(() => {
    fetchOperatingRoomsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      <div className="waiting-room-list">
        {operatingRooms?.map((ele) => {
          return (
            <div className="item-row d-flex">
              <div className="left-col">
                <div className="room-info">
                  <h4>{ele.name}</h4>
                  <h5>{ele?.room_for}</h5>
                </div>
              </div>
              <div className="right-col d-inline-flex align-items-center">
                {ele.active ? (
                  <div className="status-rw d-flex align-items-center">
                    <span className="swtich-cell2 d-inline-flex align-items-center mb-0">
                      <span className="icomoon-check icon"></span>
                      <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                    </span>
                  </div>
                ) : (
                  <div className="status-rw d-flex align-items-center">
                    <span
                      className={`swtichCell d-inline-flex align-items-center disable`}
                    >
                      <span
                        className="switch-btn flex-fill d-inline-flex align-items-center flex-shrink-0"
                        style={{ width: "32px", height: "16px" }}
                      >
                        <span className="icomoon-check icon"></span>
                        <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                      </span>
                    </span>
                  </div>
                )}

                {permissions?.edit_operating_rooms && (
                  <Button
                    color="link"
                    className="d-inline-flex align-items-center justify-content-center"
                    onClick={() => setSelectedOperatingRoom(ele)}
                  >
                    <span className="icomoon-pencil"></span>
                  </Button>
                )}
                <Button
                  color="link"
                  className="delete-btn"
                  onClick={() => {
                    handleDeleteOperetingList(ele?._id);
                  }}
                >
                  <RiDeleteBin5Line />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OperatingRoomList;
