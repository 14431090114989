import React, { useEffect, useRef, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import { sidebarPanelAction } from "../../../context/actions/sidebarPanelAction";
import PageHeader from "../../../shared/PageHeader";
import ProfileSettings from "./ProfileSettings";
import Preferences from "./Preferences";
import { useDispatch, useSelector } from "react-redux";
import { getClinicSettings } from "../../../context/actions/clinicSettings";
import AuditLog from "./AuditLog";
import RolePermission from "./RolePermission/index";
import Locations from "./Locations";
import FormTemplate from "./FormTemplate";
import { getClinicDetailsByUserId } from "../../../context/actions/clinic";
import Holiday from "./Holiday";
import Apps from "./Apps";
import LoaderUI from "../../../shared/loader";
import Automation from "./Automation";
import WorkList from "./WorkList";
import { getWaitingRoomList } from "context/actions/waitingRoom";
import AddNewRole from "./RolePermission/AddNewRole/Index";

const Settings = () => {
  const dispatch = useDispatch();
  const isFirstRenderRef = useRef();
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const permissions = permissionsData;
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  //   const handleGetClinicSettings = () => {
  //     dispatch(
  //       getClinicDetailsByUserId({}, (response) => {
  //         if (response?.status) {
  //           dispatch(getClinicSettings({}, () => {}));
  //         }
  //         setLoading(false);
  //       })
  //     );
  //   };
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };
  const handleGetWaitingList = () => {
    dispatch(getWaitingRoomList({}, () => {}));
  };
  //   useEffect(() => {
  //     if (!isFirstRenderRef.current) {
  //       setLoading(true);
  //       handleGetClinicSettings();
  //       isFirstRenderRef.current = true;
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);
  useEffect(() => {
    handleGetWaitingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading && <LoaderUI />}
      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex flex-shrink-0 toggle">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span class="material-symbols-outlined">menu</span>
          </Button>
          <PageHeader
            className="dashboard-head flex-flex-shrink-0"
            title="Settings"
          ></PageHeader>
          {activeTab === "3" && (
            <div className=" ms-auto">
              <div className="btn-blc">
                <Button
                  style={{ "Fon-Size": "14px" }}
                  onClick={handleShow}
                  color="success"
                  className="add-btn py-2 h-auto"
                >
                  ADD NEW
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="page-content d-flex flex-column flex-fill overflow-auto">
          <div className="tab-wrapper tab-wrapper-setting  d-flex flex-column flex-fill overflow-auto">
            <Nav tabs className="flex-shrink-0">
              <NavItem>
                {permissions?.view_preferences && (
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Profile Settings
                  </NavLink>
                )}
              </NavItem>
              <NavItem>
                {permissions?.view_preferences && (
                  <NavLink
                    className={{ active: activeTab === "2" }}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Preferences
                  </NavLink>
                )}
                <NavLink
                  className={{ active: activeTab === "3" }}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Roles & Permissions
                </NavLink>
                {permissions?.view_working_locations && (
                  <NavLink
                    className={{ active: activeTab === "4" }}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Locations
                  </NavLink>
                )}
                {permissions?.view_forms_and_templates && (
                  <NavLink
                    className={{ active: activeTab === "5" }}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Forms & Templates
                  </NavLink>
                )}
                <NavLink
                  className={{ active: activeTab === "6" }}
                  onClick={() => {
                    toggle("6");
                  }}
                >
                  {/* Holidays */}Work List
                </NavLink>
                <NavLink
                  className={{ active: activeTab === "7" }}
                  onClick={() => {
                    toggle("7");
                  }}
                >
                  Apps
                </NavLink>
                <NavLink
                  className={{ active: activeTab === "8" }}
                  onClick={() => {
                    toggle("8");
                  }}
                >
                  Audit Logs
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={activeTab}
              className="flex-fill overflow-auto"
            >
              <TabPane tabId="1">
                <ProfileSettings />
              </TabPane>
              <TabPane tabId="2">
                <Preferences />
              </TabPane>
              <TabPane tabId="3" className="h-100">
                <RolePermission />
              </TabPane>
              <TabPane tabId="4">
                <Locations />
              </TabPane>
              <TabPane tabId="5">
                <FormTemplate />
              </TabPane>
              <TabPane tabId="6">
                {/* <Holiday /> */}<WorkList />
              </TabPane>
              <TabPane tabId="7">
                <Apps />
              </TabPane>
              <TabPane tabId="8">
                <AuditLog />
              </TabPane>

              <TabPane tabId="9">
                <Automation />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>

      {activeTab === "3" && (
        <AddNewRole show={show} handleClose={handleClose} />
      )}
    </>
  );
};

export default Settings;
