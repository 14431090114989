import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateClinicSettings,
  updateInvoicePreferencesLogo,
} from "../../../../../context/actions/clinicSettings";
import BrowseFileInput from "../../../../../shared/BrowseFileInput";
import CheckBox from "../../../../../shared/CheckBox";
import { toasterConfig } from "utils/constants";
import { toast } from "react-toastify";

const InvoicePreferences = ({}) => {
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const [preferences, setPreferences] = useState({
    is_logo: clinic_settings?.invoice_preferences?.is_logo,
    is_credit: clinic_settings?.invoice_preferences?.is_credit,
    is_pet_name: clinic_settings?.invoice_preferences?.is_pet_name,
    is_pet_ownwer_name:
      clinic_settings?.invoice_preferences?.is_pet_ownwer_name,
    is_remaining_balance:
      clinic_settings?.invoice_preferences?.is_remaining_balance,
    is_deposit: clinic_settings?.invoice_preferences?.is_deposit,
    is_appointment_details:
      clinic_settings?.invoice_preferences?.is_appointment_details,
    is_payment_details:
      clinic_settings?.invoice_preferences?.is_payment_details,
    is_pet_address: clinic_settings?.invoice_preferences?.is_pet_address,
    is_clinic_address: clinic_settings?.invoice_preferences?.is_clinic_address,
    is_tax_id_number: clinic_settings?.invoice_preferences?.is_tax_id_number,
    is_invoice_memo: clinic_settings?.invoice_preferences?.is_invoice_memo,
  });

  const [settings] = useState({ ...clinic_settings });

  const handleUpdateSettings = (data) => {
    let body = {
      ...data,
      clinic_id: clinic_settings.clinic_id,
      update_query: "invoice_preferences",
    };
    dispatch(updateClinicSettings(body, () => {}));
  };

  const handleUpdateOnClick = (e, key) => {
    let value = e.target.checked;

    switch (key) {
      case "is_logo":
        setPreferences((p) => (p = { ...p, is_logo: value }));
        handleUpdateSettings({ ...preferences, is_logo: value });
        break;

      case "is_credit":
        setPreferences((p) => (p = { ...p, is_credit: value }));
        handleUpdateSettings({ ...preferences, is_credit: value });
        break;

      case "is_pet_name":
        setPreferences((p) => (p = { ...p, is_pet_name: value }));
        handleUpdateSettings({ ...preferences, is_pet_name: value });
        break;

      case "is_pet_ownwer_name":
        setPreferences((p) => (p = { ...p, is_pet_ownwer_name: value }));
        handleUpdateSettings({ ...preferences, is_pet_ownwer_name: value });
        break;

      case "is_remaining_balance":
        setPreferences((p) => (p = { ...p, is_remaining_balance: value }));
        handleUpdateSettings({ ...preferences, is_remaining_balance: value });
        break;

      case "is_deposit":
        setPreferences((p) => (p = { ...p, is_deposit: value }));
        handleUpdateSettings({ ...preferences, is_deposit: value });
        break;

      case "is_appointment_details":
        setPreferences((p) => (p = { ...p, is_appointment_details: value }));
        handleUpdateSettings({ ...preferences, is_appointment_details: value });
        break;

      case "is_payment_details":
        setPreferences((p) => (p = { ...p, is_payment_details: value }));
        handleUpdateSettings({ ...preferences, is_payment_details: value });
        break;

      case "is_pet_address":
        setPreferences((p) => (p = { ...p, is_pet_address: value }));
        handleUpdateSettings({ ...preferences, is_pet_address: value });
        break;

      case "is_clinic_address":
        setPreferences((p) => (p = { ...p, is_clinic_address: value }));
        handleUpdateSettings({ ...preferences, is_clinic_address: value });
        break;

      case "is_tax_id_number":
        setPreferences((p) => (p = { ...p, is_tax_id_number: value }));
        handleUpdateSettings({ ...preferences, is_tax_id_number: value });
        break;

      case "is_invoice_memo":
        setPreferences((p) => (p = { ...p, is_invoice_memo: value }));
        handleUpdateSettings({ ...preferences, is_invoice_memo: value });
        break;

      default:
        break;
    }
  };

  // const handleUpdateLogo = (e) => {
  //     let formData = new FormData();
  //     formData.append("invoice_logo", e.target.files[0])
  //     dispatch(updateInvoicePreferencesLogo(formData, () => { }))
  // }

  const handleUpdateBrandLogo = (file) => {
    if (settings?.invoice_preferences?.is_logo) {
      if (file) {
        let formData = new FormData();
        formData.append("invoice_logo", file);
        console.log(formData, "formDataformData");
        dispatch(updateInvoicePreferencesLogo(formData, () => {}));
      } else {
        toast.error("No file selected for Invoice logo!", toasterConfig);
      }
    } else {
      toast.error("Invoice logo is not active!", toasterConfig);
    }
  };
  console.log(clinic_settings);
  let invoiceLogo = "";
  if (clinic_settings?.invoice_preferences?.logo_url) {
    invoiceLogo = clinic_settings?.invoice_preferences?.logo_url
      ?.split("?")?.[0]
      ?.split("/")
      ?.slice(-1)
      ?.pop();
  }

  console.log(invoiceLogo);
  return (
    <>
      <div className="tab-row d-flex">
        <div className="tab-col lft">
          <div className="info-block">
            <h3>Invoices Preferences</h3>
            <div className="brand-logo-form">
              <div className="row-block">
                <CheckBox
                  type="checkbox"
                  label="Logo"
                  checked={!!preferences?.is_logo}
                  onChange={(e) => handleUpdateOnClick(e, "is_logo")}
                />
                <BrowseFileInput
                  onChange={handleUpdateBrandLogo}
                  message={invoiceLogo || ""}
                />
              </div>
              <div className="row-block d-flex flex-column prefer-check-item">
                <CheckBox
                  type="checkbox"
                  label="Credits"
                  checked={!!preferences?.is_credit}
                  onChange={(e) => handleUpdateOnClick(e, "is_credit")}
                />
                <CheckBox
                  type="checkbox"
                  label="Pet Name"
                  checked={!!preferences?.is_pet_name}
                  onChange={(e) => handleUpdateOnClick(e, "is_pet_name")}
                />
                <CheckBox
                  type="checkbox"
                  label="Pet’s Owner Name"
                  checked={!!preferences?.is_pet_ownwer_name}
                  onChange={(e) => handleUpdateOnClick(e, "is_pet_ownwer_name")}
                />
                <CheckBox
                  type="checkbox"
                  label="Remaining Balances"
                  checked={!!preferences?.is_remaining_balance}
                  onChange={(e) =>
                    handleUpdateOnClick(e, "is_remaining_balance")
                  }
                />
                <CheckBox
                  type="checkbox"
                  label="Deposit"
                  checked={!!preferences?.is_deposit}
                  onChange={(e) => handleUpdateOnClick(e, "is_deposit")}
                />
                <CheckBox
                  type="checkbox"
                  label="Appointment Details"
                  checked={!!preferences?.is_appointment_details}
                  onChange={(e) =>
                    handleUpdateOnClick(e, "is_appointment_details")
                  }
                />
                <CheckBox
                  type="checkbox"
                  label="Payment Method Details"
                  checked={!!preferences?.is_payment_details}
                  onChange={(e) => handleUpdateOnClick(e, "is_payment_details")}
                />
                <CheckBox
                  type="checkbox"
                  label="Pet Address"
                  checked={!!preferences?.is_pet_address}
                  onChange={(e) => handleUpdateOnClick(e, "is_pet_address")}
                />
                <CheckBox
                  type="checkbox"
                  label="Clinic Address"
                  checked={!!preferences?.is_clinic_address}
                  onChange={(e) => handleUpdateOnClick(e, "is_clinic_address")}
                />
                <CheckBox
                  type="checkbox"
                  label="Tax ID Number"
                  checked={!!preferences?.is_tax_id_number}
                  onChange={(e) => handleUpdateOnClick(e, "is_tax_id_number")}
                />
                <CheckBox
                  type="checkbox"
                  label="Invoice Memo"
                  checked={!!preferences?.is_invoice_memo}
                  onChange={(e) => handleUpdateOnClick(e, "is_invoice_memo")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tab-col flex-fill">{/* Brand Logo View */}</div>
      </div>
    </>
  );
};

export default InvoicePreferences;
