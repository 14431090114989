/*
 * @file: index.js
 * @description: It Contain environment variables.
 * @date: 21.12.2021
 * @author: Nk
 */

//For local setup
const local = {
	apiUrl: "http://localhost:",
	PORT: '4200/api/v1',//'6181/api/v1',
	socketUrl: "http://localhost:4200",
	imageBaseUrl: 'http://localhost:4200',
	profile_picture_size: '1000000', // max file size is 1 mb,
	documentPhotosPath: "/photos/documents",
	mapbox_autofill_address_key: "pk.eyJ1IjoidGNhcnJhd2F5LXZldHJlY29yIiwiYSI6ImNsbzY4amF6OTBnd2MyanMwdDd0MGw3ZngifQ.J9OHqc0Og_y16WG2DgWNXg",
  reportPhotosPath:"/photos/report"
};

//For dev server
const staging = {
  apiUrl: "https://www.backend.vetrecor.io",
  PORT: "/api/v1",
  socketUrl: "https://www.backend.vetrecor.io",
  imageBaseUrl: "https://www.backend.vetrecor.io",
  documentPhotosPath: "/photos/documents",
  reportPhotosPath:"/photos/report",
  profile_picture_size: "1000000", // max file size is 1 mb
};

//For staging server port KEY can not be remove its using in application
const development = {
	apiUrl: "https://www.api.vetrecor.io",
	PORT: '/api/v1',
    socketUrl: "https://www.api.vetrecor.io",
    imageBaseUrl: "https://www.api.vetrecor.io",
    documentPhotosPath: "/photos/documents",
	profile_picture_size: '1000000', // max file size is 1 mb
  reportPhotosPath:"/photos/report",
	mapbox_autofill_address_key: "pk.eyJ1IjoidGNhcnJhd2F5LXZldHJlY29yIiwiYSI6ImNsbzY4amF6OTBnd2MyanMwdDd0MGw3ZngifQ.J9OHqc0Og_y16WG2DgWNXg"
};

const demo = {
  apiUrl: "https://backend.vetrecor.io",
  PORT: "/api/v1",
  socketUrl: "https://backend.vetrecor.io",
  imageBaseUrl: "https://backend.vetrecor.io",
  documentPhotosPath: "/photos/documents",
  reportPhotosPath:"/photos/report",
  profile_picture_size: "1000000", // max file size is 1 mb
};

const production = {
  apiUrl: "https://vetrecorpimsprod.azurewebsites.net",
  PORT: "/api/v1",
  socketUrl: "https://vetrecorpimsprod.azurewebsites.net",
  imageBaseUrl: "https://vetrecorpimsprod.azurewebsites.net",
  documentPhotosPath: "/photos/documents",
  reportPhotosPath:"/photos/report",
  profile_picture_size: "1000000", // max file size is 1 mb
};

// console.log("process.env.REACT_APP_ENV :", process.env.REACT_APP_ENV);

if (process.env.REACT_APP_ENV === "local") module.exports = local;
else if (process.env.REACT_APP_ENV === "development") module.exports = development;
else if (process.env.REACT_APP_ENV === "demo") module.exports = demo;
else if (process.env.REACT_APP_ENV === "staging") module.exports = staging;
else if (process.env.REACT_APP_ENV === "production")
  module.exports = production;
else module.exports = staging;
