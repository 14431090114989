import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateClinicSettings } from "../../../../../context/actions/clinicSettings";

const BillingPreferences = () => {
	const dispatch = useDispatch();
	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
	const [preferences, setPreferences] = useState({
		is_charge_tax: clinic_settings?.bill_preferences?.is_charge_tax,
		is_exceed_week_hours: clinic_settings?.bill_preferences?.is_exceed_week_hours
	})

	const handleUpdateSettings = (data) => {
		let body = {
			...data,
			clinic_id: clinic_settings.clinic_id,
			update_query: "bill_preferences",
		}
		dispatch(updateClinicSettings(body));
	}

	const onChargeTaxClick = (e) => {
		let value = e.target.checked;
		setPreferences(p => p = { ...p, is_charge_tax: value })
		handleUpdateSettings({ ...preferences, is_charge_tax: value })
	}
	const onExceedHoursClick = (e) => {
		let value = e.target.checked;
		setPreferences(p => p = { ...p, is_exceed_week_hours: value })
		handleUpdateSettings({ ...preferences, is_exceed_week_hours: value })
	}
	return (
		<>
			<div className="toggle-item-blc">
				<div className="item-rw d-flex">
					<div className="form-check form-switch">
						<input checked={!!preferences?.is_charge_tax} onClick={onChargeTaxClick} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
					</div>
					<div className="txt">
						<h4>Charge Tax </h4>
						<h5>
							Click only if taxes are applicable with in your State/Province.
						</h5>
					</div>
				</div>
				<div className="item-rw d-flex">
					<div className="form-check form-switch">
						<input checked={!!preferences?.is_exceed_week_hours} onClick={onExceedHoursClick} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
					</div>
					<div className="txt">
						<h4>Don’t allow doctors to exceed 40 hours per week</h4>
						<h5>Select this setting to exempt doctors to exceed 40 hours limit to avoid overtime payment.</h5>
					</div>
				</div>
			</div>
		</>
	);
};

export default BillingPreferences;
